import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (token) => !!token;

const setSession = (token) => {
    if (token) {
        localStorage.setItem('token', token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const TokenContext = createContext(null);

export const TokenProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const token = window.localStorage.getItem('token');

                if (token && verifyToken(token)) {
                    setSession(token);
                    const response = await axios.post('/api/static-auth');
                    const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    logout();
                    // dispatch({
                    //     type: LOGOUT
                    // });
                }
            } catch (err) {
                console.error(err);
                logout();
                // dispatch({
                //     type: LOGOUT
                // });
            }
        };

        init();
    }, []);

    const staticLogin = async (email, password) => {

        const response = await axios.post('/api/static-auth', null, {
            headers: {
                'Authorization': `Bearer ${btoa(`${email}:${password}`)}`,
                'X-Auth': `Bearer ${btoa(`${email}:${password}`)}`,
            }
        });

        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('result') && response.data.result === 0) {
            console.log('response.data', response.data)
            const { token, user } = response.data;
            setSession(btoa(`${email}:${password}`));
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
        } else {

            // throw new Error('Неверный Email или пароль');
            throw new Error('Неверный логин или пароль');
        }
    };

    const login = async (email, password) => {

        const response = await axios.post('/api/sign-in', { email, password });

        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('result') && response.data.result === 0) {

            const { token, user } = response.data;
            setSession(token);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
        } else {

            // throw new Error('Неверный Email или пароль');
            throw new Error('Неверный логин или пароль');
        }
    };

    const register = async (email, password) => {

        const response = await axios.post('/api/sign-up', { email, password, });

        if (!response.hasOwnProperty('data') || !response.data.hasOwnProperty('result') || response.data.result !== 0) {

            throw new Error('Некорректный Email или пароль');
        }
    };

    const registerConfirm = async (token) => {

        const response = await axios.post('/api/sign-up-confirm', { token });

        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('result') && response.data.result === 0) {

            const { token, user } = response.data;

            setSession(token);

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
        } else {

            throw new Error('Токен не действительный');
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email) => {

        const response = await axios.post('/api/reset-password', { email, });

        if (!response.hasOwnProperty('data') || !response.data.hasOwnProperty('result') || response.data.result !== 0) {

            throw new Error('Некорректный Email или пароль');
        }
    };

    const resetPasswordConfirm = async (token, password) => {

        const response = await axios.post('/api/reset-password-confirm', { token, password, });

        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('result') && response.data.result === 0) {

            const { token, user } = response.data;

            setSession(token);

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
        } else {

            throw new Error('Токен не действительный');
        }
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <TokenContext.Provider value={{ ...state, staticLogin, login, logout, register, resetPassword, updateProfile, registerConfirm, resetPasswordConfirm }}>{children}</TokenContext.Provider>
    );
};

TokenProvider.propTypes = {
    children: PropTypes.node
};

export default TokenContext;
