import api from './axios';

export default function postRequest(url, data = {}, config = {}) {

    return new Promise((resolve, reject) => {

        api.post(url, data, config)
            .then(response => {

                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
            .then(() => {

                reject();
            });
    });
}
