import api from './axios';

export default function deleteRequest(url, data = {}) {

    return new Promise((resolve, reject) => {

        api.delete(url, {data})
            .then(response => {

                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
            .then(() => {

                reject();
            });
    });
}
