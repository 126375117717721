import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {

    const theme = useTheme();

    return (
        <Box>
            <Outlet />
        </Box>
    );
};

export default MinimalLayout;
