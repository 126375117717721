import { createSlice, current } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from '../index';
import postRequest from '../../utils/postRequest';
import deleteRequest from '../../utils/deleteRequest';
import getRequest from '../../utils/getRequest';


export const EDIT_STATUS = 'EDIT_STATUS';
export const COMPLETE_STATUS = 'COMPLETE_STATUS';
export const MAX_QUESTIONS_SELECT_LIMIT = 8;
export const MAX_QUESTIONS_LIMIT = 15;
export const SINGLE_SELECT_TYPE = 'SINGLE_SELECT_TYPE';
export const MULTIPLE_SELECT_TYPE = 'MULTIPLE_SELECT_TYPE';
export const DESCRIBING_VIEW = 'DESCRIBING_VIEW';
export const QUESTIONS_VIEW = 'QUESTIONS_VIEW';
export const CONTACT_VIEW = 'CONTACT_VIEW';
export const THANK_YOU_VIEW = 'THANK_YOU_VIEW';
export const PAYMENT_VIEW = 'PAYMENT_VIEW';
export const SHARE_VIEW = 'SHARE_VIEW';

const initialState = {
    creation: {
        id: '',
        title: '',
        sign: '',
        description: '',
        video: null,
        questions: [],
        status: null,
        contactTitle: '',
        contactText: '',
        thankYouTitle: '',
        thankYouText: '',
        payment: null,
    },
    items: null,
    itemsError: null,
    creationError: null,
    drawerOpen: true,
    publicToken: null,
    recordInterview: null,
};

const slice = createSlice({
    name: 'interviewLayout',
    initialState,
    reducers: {
        hasItemsError(state, action) {
            state.itemsError = action.payload;
        },
        setItems(state, action) {
            state.items = action.payload;
        },
        hasCreationError(state, action) {
            state.creationError = action.payload;
        },
        setCreation(state, action) {
            state.creation = {
                ...action.payload,
                questions: action.payload.questions || [],
            };
        },
        setCreationVideo(state, action) {

            state.creation.video = action.payload;
        },
        setCreationQuestions(state, action) {

            const { creation } = current(state);

            state.creation = {
                ...creation,
                questions: action.payload || [],
            };
        },
        setCreationQuestion(state, action) {

            const { creation } = current(state);

            state.creation = {
                ...creation,
                questions: creation.questions.map(question => {

                    if ((question.id && question.id === action.payload.id) || question.order === action.payload.order)
                        return {...question, ...action.payload}

                    return question;
                }),
            };
        },
        clearQuestionImage(state, action) {

            const { creation } = current(state);

            state.creation = {
                ...creation,
                questions: creation.questions.map(question => {

                    if (question.image && question.image.hasOwnProperty('id') && question.image.id && question.image.id === action.payload) {

                        return { ...question, image: null };
                    }

                    return question;
                }),
            };
        },
        setPublicToken(state, action) {
            state.publicToken = action.payload;
        },
        clearCreationVideoIfNotUpload(state) {

            if (!current(state)?.creation) {

                state.creation.video = null;
            }
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        setRecordInterview(state, action) {
            state.recordInterview = action.payload;
        },
    },
});

export default slice.reducer;

export const {openDrawer, setCreation, clearCreationVideoIfNotUpload, setCreationVideo, setCreationQuestions, clearQuestionImage, setCreationQuestion, setRecordInterview} = slice.actions;

export const selectItems = ({interviewLayout: {items}}) => items;
export const selectItemsError = ({interviewLayout: {itemsError}}) => itemsError;
export const selectCreation = ({interviewLayout: {creation}}) => creation;
export const selectCreationQuestions = ({interviewLayout: {creation}}) => creation.questions;
export const selectCreationError = ({interviewLayout: {creationError}}) => creationError;
export const selectPublicToken = ({interviewLayout: {publicToken}}) => publicToken;
export const selectRecordInterview = ({interviewLayout: {recordInterview}}) => recordInterview;

export async function initInterviewLayout(publicToken) {

    try {
        const response = await postRequest('/api/public/interview-layout/init', {publicToken});

        dispatch(slice.actions.setPublicToken(response.interviewLayout.publicToken));

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

    } catch (error) {

        console.log({error});
    }
}

export async function setInterviewLayoutDescribing({ publicToken, interviewLayoutId, title, sign, description }) {

    try {
        const response = await postRequest('/api/public/interview-layout/set-describing', { publicToken, interviewLayoutId, title, sign, description });

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

    } catch (error) {

        console.log({error});
    }
}

export async function uploadVideo(formData) {

    try {
        const response = await postRequest('/api/public/interview-layout/upload-video', formData);

        dispatch(slice.actions.setCreationVideo(response.video));

    } catch (error) {

        console.log({error});
    }
}

export async function deleteVideo({ publicToken, interviewLayoutId }) {

    try {
        await deleteRequest('/api/public/interview-layout/remove-video', { publicToken, interviewLayoutId });

        dispatch(slice.actions.setCreationVideo(null));

    } catch (error) {

        console.log({error});
    }
}

export async function uploadQuestionImage(formData, question) {

    try {
        const response = await postRequest('/api/public/interview-layout/upload-question-image', formData);

        dispatch(slice.actions.setCreationQuestion({ ...question, ...response.question }));

    } catch (error) {

        console.log({error});
    }
}

export async function deleteQuestionImage({publicToken, imageId}) {

    try {
        await deleteRequest('/api/public/interview-layout/remove-question-image', { publicToken, id: imageId });

        dispatch(slice.actions.clearQuestionImage(imageId));

    } catch (error) {

        console.log({error});
    }
}

export async function setInterviewLayoutQuestions({ publicToken, interviewLayoutId, questions }) {

    try {
        const response = await postRequest('/api/public/interview-layout/set-questions', { publicToken, interviewLayoutId, questions });

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

    } catch (error) {

        console.log({error});
    }
}

export async function setInterviewLayoutContact({ publicToken, interviewLayoutId, contactTitle, contactText }) {

    try {
        const response = await postRequest('/api/public/interview-layout/set-contacts', { publicToken, interviewLayoutId, contactTitle, contactText });

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

    } catch (error) {

        console.log({error});
    }
}

export async function setInterviewLayoutThankYou({ publicToken, interviewLayoutId, thankYouTitle, thankYouText }) {

    try {
        const response = await postRequest('/api/public/interview-layout/set-thank-you', { publicToken, interviewLayoutId, thankYouTitle, thankYouText });

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

    } catch (error) {

        console.log({error});
    }
}

export async function payment({ publicToken, interviewLayoutId }) {

    try {
        const response = await postRequest('/api/public/interview-layout/payment', { publicToken, interviewLayoutId });

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

        return response;

    } catch (error) {

        console.log({error});

        return null;
    }
}

export async function publish({ publicToken, interviewLayoutId }) {

    try {
        const response = await postRequest('/api/public/interview-layout/publish', { publicToken, interviewLayoutId });

        dispatch(slice.actions.setCreation(formatCreationResponse(response.interviewLayout)));

        return response;

    } catch (error) {

        console.log({error});

        return null;
    }
}

export async function preview({ publicToken, interviewLayoutId }) {

    try {
        const response = await getRequest('/api/public/interview-layout/preview', { publicToken, interviewLayoutId });

        dispatch(slice.actions.setRecordInterview(formatCreationResponse(response.interviewLayout)));

        return response.interviewLayout;

    } catch (error) {

        console.log({error});

        return null;
    }
}


// export function fetchItems() {
//
//     return async () => {
//
//         try {
//
//             const response = axios.get('/api/interview-layouts');
//
//             dispatch(slice.actions.setItems(response.data.items));
//
//         } catch (error) {
//
//             dispatch(slice.actions.hasItemsError(error));
//         }
//     };
// }
//
// export function fetchCreation() {
//
//     return async () => {
//
//         try {
//
//             const response = axios.get('/api/interview-layout-creation');
//
//             dispatch(slice.actions.setCreation(response.data.creation));
//
//         } catch (error) {
//
//             dispatch(slice.actions.hasCreationError(error));
//         }
//     };
// }



function formatCreationResponse(interviewLayout) {

    if (interviewLayout.questions) {

        interviewLayout.questions.sort((questionA, questionB) => questionA.order - questionB.order);

        interviewLayout.questions.map(question => {

            if (question.select) {

                question.select.sort((optionA, optionB) => optionA.order - optionB.order);
            }

            return question;
        });
    }

    return {
        id: interviewLayout.id || null,
        title: interviewLayout.title || '',
        sign: interviewLayout.sign || '',
        description: interviewLayout.description || '',
        video: interviewLayout.video || null,
        questions: interviewLayout.questions,
        status: interviewLayout.status,
        contactTitle: interviewLayout.contactTitle || '',
        contactText: interviewLayout.contactText || '',
        thankYouTitle: interviewLayout.thankYouTitle || '',
        thankYouText: interviewLayout.thankYouText || '',
        payment: interviewLayout.payment || false,
    };
}