import { createSlice, current } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import postRequest from '../../utils/postRequest';
import deleteRequest from '../../utils/deleteRequest';
import getRequest from '../../utils/getRequest';

const initialState = {
    currentFacility: null,
    currentFacilityType: null,
    facilities: null,
    facilityTypes: null,
};

const slice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        setCurrentFacility(state, action) {
            state.currentFacility = action.payload;
        },
        setCurrentFacilityType(state, action) {
            state.currentFacilityType = action.payload;
        },
        setFacilities(state, action) {
            state.facilities = action.payload;
        },
        setFacilityTypes(state, action) {
            state.facilityTypes = action.payload;
        },
    }
});

export default slice.reducer;

export const {setCurrentFacility, setFacilities, setFacilityTypes, setCurrentFacilityType} = slice.actions;

export const selectCurrentFacility = ({facility: {currentFacility}}) => currentFacility;
export const selectCurrentFacilityType = ({facility: {currentFacilityType}}) => currentFacilityType;
export const selectFacilities = ({facility: {facilities}}) => facilities;
export const selectFacilityTypes = ({facility: {facilityTypes}}) => facilityTypes;

export async function fetchCurrentFacility({surveyId}) {

    try {
        const response = await getRequest(`/facility/${surveyId}`);
        console.log({response});
        dispatch(slice.actions.setCurrentFacility(response.facility));

    } catch (error) {

        console.log({error});
    }
}

export async function fetchFacilities({surveyLayoutSurveyLayoutId}) {

    try {
        const response = await getRequest(`/api/facilities/${surveyLayoutSurveyLayoutId}`);
        console.log({response});
        dispatch(slice.actions.setFacilities(response.facilities));
        dispatch(slice.actions.setCurrentFacilityType(response.facilityType));

    } catch (error) {

        console.log({error});
    }
}

export async function fetchFacilityTypes() {

    try {
        const response = await getRequest(`/api/facilities`);
        console.log({response});
        dispatch(slice.actions.setFacilityTypes(response.facilityTypes));

    } catch (error) {

        console.log({error});
    }
}

export async function deleteFacility({surveyId}) {

    try {
        const response = await deleteRequest(`/api/facility/${surveyId}`);
        console.log({response});
        // dispatch(slice.actions.setCurrentFacility(null));

        return response;

    } catch (error) {

        console.log({error});
    }

    return null;
}