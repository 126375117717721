/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create({
    // validateStatus () {
    //     return true;
    // },
    withCredentials: true
});

axiosServices.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axiosServices.defaults.headers.post['Content-Type'] = 'application/json';
axiosServices.defaults.baseURL = process.env.REACT_APP_API_URL;


// interceptor for http
axiosServices.interceptors.request.use(
    (config) => {

        const authToken = localStorage.getItem('token');

        if (authToken) {

            const headers = {
                'Authorization': `Bearer ${authToken}`,
                'X-Auth': `Bearer ${authToken}`,
            };
            axios.defaults.headers.common = headers;
            config.headers = headers;
        }

        return config;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

// function is401(status) {
//     return status === 401;
// }

export default axiosServices;