import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MinimalLayout from "../layout/MinimalLayout";

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Temp1 = Loadable(lazy(() => import('views/pages/temp/Temp1')));
const Facilities = Loadable(lazy(() => import('views/facility/Facilities')));
const FacilityTypes = Loadable(lazy(() => import('views/facility/FacilityTypes')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    // element: (
    //     <AuthGuard>
    //         <MainLayout />
    //     </AuthGuard>
    // ),
    element: (
        <AuthGuard>
            <MinimalLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <FacilityTypes />,
        },
        {
            path: '/facilities',
            element: <FacilityTypes />,
        },
        {
            path: '/facilities/:surveyLayoutSurveyLayoutId',
            element: <Facilities />,
        },
        // {
        //     path: '/',
        //     element: <SamplePage />
        // },
        // {
        //     path: '/sample-page',
        //     element: <SamplePage />
        // },
        // {
        //     path: '/temp1',
        //     element: <Temp1 />
        // },
    ]
};

export default MainRoutes;
