import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
// const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
// const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));

const SignIn = Loadable(lazy(() => import('views/pages/authentication/layout/SignIn')));
const SignUp = Loadable(lazy(() => import('views/pages/authentication/layout/SignUp')));
const SignUpConfirm = Loadable(lazy(() => import('views/pages/authentication/SignUpConfirm')));
const NeedEmailConfirmation = Loadable(lazy(() => import('views/pages/authentication/NeedEmailConfirmation')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/layout/ForgotPassword')));
const NeedResetPasswordConfirmation = Loadable(lazy(() => import('views/pages/authentication/NeedResetPasswordConfirmation')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/layout/ResetPassword')));
// const Temp2 = Loadable(lazy(() => import('views/pages/temp/Temp2')));
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        // {
        //     path: '/sample-page3',
        //     element: <SamplePage />
        // },
        {
            path: '/login',
            element: <SignIn />
        },
        // {
        //     path: '/register',
        //     element: <SignUp />
        // },
        // {
        //     path: '/sign-up-confirm/:token',
        //     element: <SignUpConfirm />
        // },
        // {
        //     path: '/need-email-confirmation',
        //     element: <NeedEmailConfirmation />
        // },
        // {
        //     path: '/forgot-password',
        //     element: <ForgotPassword />
        // },
        // {
        //     path: '/need-forgot-password-confirmation',
        //     element: <NeedResetPasswordConfirmation />
        // },
        // {
        //     path: '/reset-password/:token',
        //     element: <ResetPassword />
        // },
        // {
        //     path: '/temp2',
        //     element: <Temp2 />
        // },
        // {
        //     path: '/register',
        //     element: <AuthRegister />
        // },
        // {
        //     path: '/forgot',
        //     element: <AuthForgotPassword />
        // },
        // {
        //     path: '/login',
        //     element: <AuthLogin />
        // },
        // {
        //     path: '/register',
        //     element: <AuthRegister />
        // },
        // {
        //     path: '/forgot',
        //     element: <AuthForgotPassword />
        // },
    ]
};

export default LoginRoutes;
