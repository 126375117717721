// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import { lazy } from 'react';
import urls from '../utils/url';

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const Facility = Loadable(lazy(() => import('views/facility/Facility')));
// const Facilities = Loadable(lazy(() => import('views/facility/Facilities')));
// const FacilityTypes = Loadable(lazy(() => import('views/facility/FacilityTypes')));
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
// const InterviewLayouts = Loadable(lazy(() => import('views/interview-layout/list/InterviewLayouts')));
// const InterviewLayoutWizard = Loadable(lazy(() => import('views/interview-layout/wizard/Wizard')));
// const InterviewLayout = Loadable(lazy(() => import('views/interview-layout/item/InterviewLayout')));
// const InterviewRecordView = Loadable(lazy(() => import('views/interview-record/InterviewRecordView')));
// const InterviewLayoutPreview = Loadable(lazy(() => import('views/interview-layout/preview/InterviewLayoutPreview')));

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/facility/:surveyId',
            element: <Facility />,
        },
        // {
        //     path: '/facilities',
        //     element: <FacilityTypes />,
        // },
        // {
        //     path: '/facilities/:surveyLayoutSurveyLayoutId',
        //     element: <Facilities />,
        // },
        // {
        //     path: urls.interviewLayouts,
        //     element: <InterviewLayouts/>,
        // },
        // {
        //     path: urls.interviewLayoutsWizard,
        //     element: <InterviewLayoutWizard/>,
        // },
        // {
        //     path: `${urls.interviewLayoutsItem}/:interviewLayoutId`,
        //     element: <InterviewLayout/>,
        // },
        // {
        //     path: `${urls.interviewLayoutsPreview}/:interviewLayoutId`,
        //     element: <InterviewLayoutPreview/>,
        // },
        // {
        //     path: `${urls.interviewRecord}/:interviewLayoutId`,
        //     element: <InterviewRecordView/>,
        // },
    ],
};

export default AuthenticationRoutes;
