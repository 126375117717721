import api from './axios';

export default function getRequest(url, params = {}, config = {}) {

    return new Promise((resolve, reject) => {

        api.get(url, {...config, params})
            .then(response => {

                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
            .then(() => {

                reject();
            });
    });
}
